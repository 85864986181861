body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fundo {
    position: relative;
    margin: 0;
    padding: 0;
    background-image: url("../public/imagens/fundo1.jpg");
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.fundo::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
}

.fundo > * {
    position: relative;
    z-index: 2;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-15 {
    padding: 15px !important;
}

.header {
    background-color: #f2f2f2;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.sitename {
    font-size: 1.5rem;
    color: #a02020;
}

.nav-link {
    position: relative;
    padding: 0.5rem 1rem;
    color: #a02020 !important;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #a02020;
}

.nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #a02020;
    transition: width 0.3s ease;
}

.nav-link:hover::after {
    width: 100%;
}

.logo {
    margin-bottom: 4px;
    margin-right: 5px;
    border-radius: 50px;
}

.pesquisa-container {
    position: relative;
    margin-top: 100px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 150px;
}

.pesquisa-resultados {
    position: relative;
    margin-top: 100px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    padding-bottom: 150px;
}

.pesquisa-content {
    background-color: rgb(242, 242, 242, 0.5);
    padding: 20px 30px;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    height: 140px;
    border-radius: 5px;
}

.pesquisa-content .form-control {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1rem;
}

.pesquisa-content .form-control:focus {
    border-color: rgb(138, 59, 42);
    box-shadow: 0 0 0 0.25rem rgba(138, 59, 42, 0.25);
}

.pesquisa-content .limpar {
    font-size: 12px;
    color: black;
    text-decoration: none;
}

.pesquisa-content .limpar:hover {
    color: black;
}

.text-left {
    text-align: left;
}

.icone-pesquisa .btn-primary {
    background-color: #c9b352;
    border-color: #c9b352;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
    padding-bottom: 8px;
    margin-top: 20px;
}

.icone-pesquisa .btn-primary:hover {
    background-color: #bfaa4f;
    border-color: #bfaa4f;
}

.icone-pesquisa .btn-primary:focus-visible {
    background-color: #bfaa4f;
    border-color: #bfaa4f;
    outline: none;
    box-shadow: none;
}

.icone-pesquisa button:focus,
.icone-pesquisa button:active {
    outline: none;
    box-shadow: none;
    border-color: #bfaa4f;
    background-color: #bfaa4f;
}

.icone-pesquisa .btn-check:checked + .btn,
.icone-pesquisa .btn.active,
.icone-pesquisa .btn.show,
.icone-pesquisa .btn:first-child:active,
.icone-pesquisa :not(.btn-check) + .btn:active {
    color: transparent !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.pesquisa {
    padding-top: 20px;
}

.pesquisa-inputs input {
    height: 38px;
}

.casas-destaque {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top;
    text-align: center;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 150px;
    font-weight: bold;
}

.titulo {
    color: white;
    width: 100%;
}

.titulo-menor {
    font-size: 1.2em;
    margin: 0;
}

.titulo-maior {
    font-size: 2.7em;
    margin: 0;
}

.barra {
    width: 100%;
    height: 4px;
    background-color: #a02020;
    margin: 10px auto 0;
    max-width: 200px;
}

.casa-card {
    border-radius: 8px;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    margin-bottom: 30px;
}

.casas-destaque .casa-card:hover {
    transform: scale(1.05);
}

.casa-imagem-container {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}

.casa-imagem-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.casas-resultados .casa-imagem-container {
    cursor: pointer;
}

.casa-valor {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ae9c49;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.2em;
    font-weight: 400;
}

.casa-localizacao {
    position: absolute;
    top: 10px;
    left: 10px;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
    font-weight: 400;
}

.casas-container {
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.casa-eficiencia {
    z-index: 100;
    text-align: initial;
}

.casa-eficiencia img {
    height: 40px;
}

.casa-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ae9c49;
    color: white;
    padding: 10px;
    display: none;
    transition: all 0.3s;
}

.casa-card:hover .casa-info {
    display: block;
    text-align: center;
}

.casa-info span {
    width: 8px;
    height: 8px;
    font-size: 0.9rem;
    font-weight: 400;
}
.casa-info svg {
    margin-bottom: 7px;
    margin-right: 3px;
}
body {
    background-color: #f2f2f2 !important;
}

.vender-casa {
    margin-top: 140px;
    color: black;
}

.btn-vender {
    background-color: #a02020 !important;
    border-color: #a02020 !important;
    color: white !important;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

footer ul {
    padding-left: 0;
    margin: 0;
}

footer ul li {
    list-style: none;
}

footer ul li a {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s;
}

footer ul li a:hover {
    color: #ccc;
}

footer p {
    margin: 0;
}

.footer-1linha {
    margin-bottom: 10px;
}

.div-footer {
    margin-top: 50px;
}

.div-footer {
    margin-top: 50px;
}

.error-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.error-page-container {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-page-logo {
    width: 450px;
    height: auto;
    margin-bottom: 20px;
}
.error-message h1 {
    font-size: 2em;
    color: #333333;
    margin-bottom: 10px;
}

.error-message p {
    font-size: 1.2em;
    color: #666666;
    margin-bottom: 10px;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.preloader-logo {
    width: 600px;
    height: auto;
}

.btn-limpar {
    margin-left: 10px;
}

.fixed-col {
    flex: 0 0 800px;
    max-width: 800px;
}

.flexible-col {
    flex: 1;
}

.casa-detalhes-eficiencia img {
    height: 20px;
    margin-left: 7px;
    margin-bottom: 4px;
}

.casa-detalhes-container {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px 80px 80px 80px;
    background-color: #f2f2f2;
}

.casa-detalhes-card {
    width: 100%;
    height: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.casa-detalhes-card .carousel {
    width: 100%;
    height: 600px;
}

.casa-detalhes-card .carousel-item {
    width: 100%;
    height: 100%;
}

.casa-detalhes-card .carousel-item img {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.casa-detalhes-card .card-body {
    padding: 20px;
}

.casa-detalhes-card .card-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.casa-detalhes-card .card-text {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
}

.casa-detalhes-card .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.casa-detalhes-card .row span {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
}

.casa-detalhes-card .row span svg {
    margin-right: 5px;
}

.casa-descricao {
    flex: 1 1 45%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.casa-descricao h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.casa-descricao p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    text-align: justify;
}

.casa-contactos {
    margin-top: 20px;
}

.casa-contactos h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.casa-contactos p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    display: flex;
    align-items: center;
}

.casa-contactos p svg {
    margin-right: 10px;
}

/* RESULTADOS */

.modal-image {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 210px);
    object-fit: contain;
}

.carousel-image-container {
    cursor: pointer;
}

.resultados .casa-info {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.casas-resultados {
    background-color: #f2f2f2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    justify-items: center;
    gap: 20px;
    padding-top: 50px;
    padding-right: 100px;
    padding-left: 100px;
    padding-bottom: 150px;
}

.resultados {
    background-color: #f2f2f2;
    padding-top: 30px;
    margin-bottom: 20px;
}

.resultados-container .bold {
    font-weight: bold;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    width: 5%;
}

.custom-carousel .carousel-control-prev {
    left: -7%;
}

.custom-carousel .carousel-control-next {
    right: -7%;
}

.custom-carousel .carousel-indicators {
    bottom: -30px;
}

.logo {
    max-width: 100%;
    height: auto;
    max-height: 30px;
}

.logo1 {
    max-width: 40px;
    margin-right: 10px;
    height: auto;
}

.ordenar {
    z-index: 1000;
}

@media (max-width: 768px) {
    .logo {
        max-height: 17px;
    }

    .pesquisa-content {
        padding-top: 40px;
        height: 330px;
    }

    .pesquisa-content .inputs {
        margin-bottom: 10px;
    }

    .casa-card {
        margin-bottom: 20px;
    }

    .casas-resultados {
        padding-top: 50px;
        padding-right: 10px;
        padding-left: 10px;
        padding-bottom: 15px;
    }

    .casa-detalhes-container {
        padding: 10px 20px 20px 20px;
    }

    .casa-detalhes-card .carousel {
        height: 250px;
    }

    .casa-detalhes-card .carousel-item img {
        height: 250px;
    }

    .casa-detalhes-card .card-body {
        padding: 15px;
    }

    .casa-detalhes-card .card-title {
        font-size: 1.25rem;
    }

    .casa-detalhes-card .card-text {
        font-size: 0.875rem;
    }

    .casa-detalhes-card .row span {
        font-size: 0.875rem;
    }

    .casa-descricao,
    .casa-contactos {
        flex: 1 1 100%;
        margin-bottom: 20px;
    }

    .titulo-menor {
        font-size: 0.7em;
        margin: 0;
    }

    .titulo-maior {
        font-size: 1em;
        margin: 0;
    }

    .logo {
        max-width: 100%;
        height: auto;
        max-height: 10px;
    }
}

.sobre-nos-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    align-items: center;
    margin-top: 100px;
    flex-direction: row;
}

.sobre-nos-logo {
    margin-right: 20px;
}

.sobre-nos-logo img {
    width: 400px;
}

.sobre-nos-text {
    text-align: justify;
}

.sobre-nos-text h1 {
    margin-top: 0;
    font-size: 2.5em;
    color: #333333;
}

.sobre-nos-text p {
    margin-top: 20px;
    font-size: 1.2em;
    line-height: 1.6;
    color: #666666;
}

@media (max-width: 600px) {
    .sobre-nos-container {
        flex-direction: column;
        text-align: center;
    }

    .sobre-nos-logo {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .sobre-nos-text {
        text-align: justify;
    }

    .sobre-nos-logo img {
        width: 100%;
        max-width: 300px;
    }
}
